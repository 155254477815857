$(document).ready(function() {
	if(!document.querySelector('.signup-wrap')) return false;
	var form = {
		el: document.getElementById('signUpForm'),
		init: function(){
			form.addEvent();
		},
		checkPassword: function(){
			var _this = form.el;
			var _fields = {
				password: $(_this).find('#txtBoxPassword').get(0),
				passwordConfirm: $(_this).find('#txtBoxPasswordConfirm').get(0)
			};
			var $block = $(_fields.password).parents('.field-block'),
				$confirmBlock = $(_fields.passwordConfirm).parents('.field-block');
			var result = true;

			if(_fields.password.value != '' && form.checkPasswordPattern(_fields.password.value)) {
				if(_fields.password.value == _fields.passwordConfirm.value) {
					$confirmBlock.removeClass('error');
				}else {
					$(_fields.passwordConfirm).focus();
					$confirmBlock.addClass('error').find('.match').show().siblings().hide();
					result = false;
				}
				$block.removeClass('error');
			}else {
				$(_fields.password).focus();
				$block.addClass('error').find('.invalid').show().siblings().hide();
				result = false;
			}

			return result;
		},
		checkPasswordPattern: function(val){
			var result = false;
			var falseCnt = 0;
			if (val.length > 7) {
				var n = /^.*(?=.*\d).*$/;
				result = n.test(val);
				if(!result) {
					falseCnt++;
				}
				n = /^.*(?=.*[a-z]).*$/;
				result = n.test(val);
				if(!result) {
					falseCnt++;
				}
				n = /^.*(?=.*[A-Z]).*$/;
				result = n.test(val);
				if(!result) {
					falseCnt++;
				}
				n= /^.*(?=.*[!@#$%^*()\-_=+\\\|\[\]{};:\'",.<>\/?]).*$/;
				result = n.test(val);
				if(!result) {
					falseCnt++;
				}
				
				if(falseCnt > 1) {
					result = false;
				} else {
					result = true;
				}
			}
			return result;
		},
		checkBirthDate: function(){
			var _this = form.el;
			var _fields = {
				birthDate: $(_this).find('#requestDate').get(0)
			};
			var $block = $(_fields.birthDate).parents('.field-block');
			var result = true;
			if(form.checkBirthDatePattern(_fields.birthDate.value)){
				$block.addClass('error').find('.ageInvalid').show().siblings().hide();
				result = false;
			}
			return result;
		},
		checkBirthDatePattern: function(val){
			var birthDate = val.replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$1-$2");
			var birthday = new Date(birthDate);
			var today = new Date();
			var years = today.getFullYear() - birthday.getFullYear();
			birthday.setFullYear(today.getFullYear());
			if (today < birthday) years--;
			var result = false;
			if (years < 16) {
				result = true;
			}
			return result;
		},
		// DC-2151 standard javascript validation on firstName and lastName
		checkFName: function(){
			var _this = form.el;
			var _fields = {
				firstname: $(_this).find('#txtBoxFName').get(0)
			};
			var $fBlock = $(_fields.firstname).parents('.field-block');
			var result = true;

			if(_fields.firstname.value != '' && !form.checkNamePattern(_fields.firstname.value)) {
				$fBlock.removeClass('error');
			}else {
				if(_fields.firstname.value != '') {
					$(_fields.firstname).focus();
					$fBlock.addClass('error').find('.invalid').show().siblings().hide();
				}
				result = false;
			}
			
			return result;
		},
		checkLName: function(){
			var _this = form.el;
			var _fields = {
				lastname: $(_this).find('#txtBoxLName').get(0)
			};
			var $lBlock = $(_fields.lastname).parents('.field-block');
			var result = true;

			if(_fields.lastname.value != '' && !form.checkNamePattern(_fields.lastname.value)) {
				$lBlock.removeClass('error');
			}else {
				if(_fields.lastname.value != '') {
					$(_fields.lastname).focus();
					$lBlock.addClass('error').find('.invalid').show().siblings().hide();
				}
				result = false;
			}

			return result;
		},
		checkNamePattern: function(val){
			var result = false;
			var n = /[~!@#$%^&*()_+|<>?:;{}`\-\=\\\,.'"\[\]/]/;
			result = n.test(val);
			return result;
		},
		beforeSubmit: function(e){
			e.preventDefault();
			
			grecaptcha.enterprise.execute($("#sitKey").val(), {action: 'login'}).then(function(token) {
				$('#reCaptchaToken').val(token);
				$('body').trigger('ajaxLoadBefore');
	
				var _this = e.currentTarget;
				
				if($(_this).checkValidation() && form.checkPassword() && form.checkBirthDate() && form.checkFName() && form.checkLName()) {
	                _this.submit();
				}else{
					$('body').trigger('ajaxLoadEnd');
				}
			});
		},
		addEvent: function(){
			$(form.el).on({
				submit: form.beforeSubmit
			});
			$(form.el).on({
				keydown: function(e){
					if(e.keyCode==13 && e.currentTarget.value != '') {
						
						var elId = $(e.target).attr('id');
						
						if(elId == "txtBoxPassword") {
							if(!form.checkPassword()) {
								e.preventDefault();
							}
						} else if(elId == "txtBoxFName") {
							if(!form.checkFName()) {
								e.preventDefault();
							}
						} else if(elId == "txtBoxLName") {
							if(!form.checkLName()) {
								e.preventDefault();
							}
						}
					}
				},
                /* Start of LGEUS-11769 */
				keyup: function (e) {
					var _this = form.el;
					var _fields = {
						password: $(_this).find('#txtBoxPassword').get(0),
						firstname: $(_this).find('#txtBoxFName').get(0),
						lastname: $(_this).find('#txtBoxLName').get(0)
					};
					
					var $block = $(_fields.password).parents('.field-block'),
						$fBlock = $(_fields.firstname).parents('.field-block'),
						$lBlock = $(_fields.lastname).parents('.field-block');

					var elId = $(e.target).attr('id');
					
					if(elId == "txtBoxPassword") {
						if (e.currentTarget.value == '' || form.checkPasswordPattern(_fields.password.value)) {
							// remove password requirement
							$block.removeClass('error');
						}
					} else if(elId == "txtBoxFName") {
						if (e.currentTarget.value == '' || !form.checkNamePattern(_fields.firstname.value)) {
							// remove password requirement
							$fBlock.removeClass('error');
						}
					} else if(elId == "txtBoxLName") {
						if (e.currentTarget.value == '' || !form.checkNamePattern(_fields.lastname.value)) {
							// remove password requirement
							$lBlock.removeClass('error');
						}
					}
				}
                /* End of LGEUS-11769 */
			}, 'input[type="password"],#txtBoxFName,#txtBoxLName');
		}
	};

	form.init();
	
	if (document.querySelector('input.email')) { 
		$('button[type="submit"]').attr('disabled', true); 
		var oldVal = '';
        var duplicateUser = {
            el: document.querySelector('input.email'),
			txtEmail: document.getElementById('txtBoxEmail'),
            init: function () {
				/* Start of LGEUS-1093 */
            	$(duplicateUser.txtEmail).on({
					focusout: function(e) {
						e.preventDefault();
						var _this = document.getElementById('signUpForm');
						if ($(document.getElementById('txtBoxEmail')).checkValidation()) {
							var url = _this.getAttribute('check-data-url');
							var param = xssfilter($(_this).serialize());
							var methodType = $(_this).data('ajaxMethod');

							$('body').trigger('ajaxLoadBefore');
							ajax.call(url, param, 'json', function (data) {
								$('body').trigger('ajaxLoadEnd');
								$("#txtBoxEmail").siblings('.error-msg').find('span').hide();
								if (data) {
									if(data.returnCode == 200){
										//$('#modal-available-user').modal('show');
										$('button[type="submit"]').attr('disabled', false);
										oldVal = $(duplicateUser.el).val();
									}else if(data.returnCode == 406){
										var idType = data.idType;
										if(idType != "") {
							 				$('#modal-3rd-party .modal-form-area .error-msg').hide();
							 				$('#thirdpartySubmit').attr('disabled', false);
							 				$('#thirdIdType').val(idType);
							 				$('#thirdEmail').val($('input[name=email]').val());
					 						$('#modal-3rd-party').modal('show');
					 						$('#3rdPartyMsg').text('').text(errorMessage);
							 			} else {
											//$('.error-msg .ajax-msg').text(data.returnMessage);
											//$("#txtBoxEmail").siblings('.error-msg').show().find('.ajax-msg').show();
											$('#modal-duplicate-user').modal('show');
											$('button[type="submit"]').attr('disabled', true);
							 			}
									}else{
										//$('.error-msg .ajax-msg').hide();
									}
								}else{
									$('.error-msg .ajax-msg').text('error');
									$('.error-msg .ajax-msg').show();
									$('button[type="submit"]').attr('disabled', true);
								}
							}, methodType);
						}
					}
				});
				/* End of LGEUS-1093 */

                $(duplicateUser.el).on("propertychange change keyup paste input", function() {
                	var currentVal = $(this).val();
                    if(currentVal == oldVal) {
                    	$('#signUpBtn').attr('disabled', false); 
                        return;
                    }
                	$('#signUpBtn').attr('disabled', true); 
                });
            }
        };

        duplicateUser.init();
    }
	
	var thirdparty = {
		el: document.getElementById('thirdpartyForm'), // form
		init: function(){
			thirdparty.addEvent();
		},
		beforeSubmit: function(e){
			e.preventDefault();
			$('#modal-3rd-party .modal-form-area .error-msg').hide();
			grecaptcha.enterprise.execute($("#sitKey").val(), {action: 'sendThirdpartyCombineMail'}).then(function(token) {
				$('#thirdpartyReCaptchaToken').val(token);
				var idType = $("#thirdIdType").val();
				var email = $("#thirdEmail").val();
				if(idType != "" && email != "") {
//						$.ajax({
//							url: $thirdpartyForm.data('url'),
//							type: 'post',
//							data: {
//								"email" : email,
//								"idType" : idType
//							},
//							dataType:'json',
//							beforeSend: function(w) {
//								$('body').trigger('ajaxLoadBefore');
//								$('#modal-3rd-party .modal-form-area .error-msg').show();
//							},
//							success: function (d) {
//								$('body').trigger('ajaxLoadEnd');
//								$('#modal-3rd-party').modal('hide');
//							},
//							error: function (request, status, error) {
//								$('#modal-3rd-party .modal-form-area .error-msg').show();
//								$('#modal-3rd-party .modal-form-area .error-msg .invalid').show().siblings().hide();
//								$('body').trigger('ajaxLoadEnd');
//							}
//						});
					thirdparty.el.submit();
					
				} else {
					$('#modal-3rd-party .modal-form-area .error-msg').show();
					$('#modal-3rd-party .modal-form-area .error-msg .invalid').show().siblings().hide();
					$('body').trigger('ajaxLoadEnd');
				}
			});
		},
		addEvent: function(){
			$(thirdparty.el).on({
				submit: thirdparty.beforeSubmit
			});
		}
	};
	thirdparty.init();
});
